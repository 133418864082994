<template>
  <v-layout justify-center>
    <v-flex sm8 md6 lg3>
      <v-snackbar v-model="snackbarAuth" :color="color">
        {{ text }}
        <v-btn text @click="snackbarAuth = false">
          Close
        </v-btn>
      </v-snackbar>
      <v-card class="elevation-12" height="358px">
        <v-card-title><v-flex class="text-xs-center"> <h3 class="headline mb-0" color="primary">Set Password</h3></v-flex></v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text class="text-xs-center">
            <v-text-field prepend-icon="mdi-account" label="Username" type="text" v-model="username" disabled></v-text-field>
            <v-text-field prepend-icon="mdi-lock" type="password" label="New password" v-model="resetObj.password" :rules="$_requiredValidation"></v-text-field>
            <v-text-field prepend-icon="mdi-lock" type="password" label="Confirm password" v-model="resetObj.confirmPassword" :rules="$_passwordConfirmationRules"></v-text-field>
          </v-card-text>
          <v-flex class="text-center">
            <v-btn color="primary" :loading="loading" :disabled="loading" @click="savePassword">Save</v-btn>
          </v-flex>
        </v-form>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialog" width="400px">
      <v-card>
        <v-card-title>
          Message
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <span class="font-weight-medium">Password changed successfully. Please try login </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="primary" @click="$router.push('/login')">Ok</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      valid: true,
      resetObj: {},
      username: '',
      snackbarAuth: false,
      text: 'text',
      color: 'success',
      dialog: false
    }
  },
  created () {
    this.$i18n.locale = 'no'
    this.$store.dispatch('logout')
    this.getUserDetailsByToken()
    this.resetObj.token = this.$route.query.token
  },
  methods: {
    getUserDetailsByToken () {
      this.$api.execute('get', `users/get_by_otp?domain=${this.$route.query.domain}&otp=${this.$route.query.token}`)
        .then((response) => {
          if (response) {
            this.$store.dispatch('logout')
            this.username = response.data.username
            this.resetObj.username = this.username
            this.resetObj.domain = this.$route.query.domain
          }
        })
    },
    savePassword () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.execute('post', 'users/reset_password', this.resetObj)
          .then((response) => {
            if (response) {
              this.dialog = true
            }
          })
          /* eslint-disable */
          .catch((error) => {
            this.loading = false
            this.$store.commit('logout')
          })
      } else {
        return false
      }
    }
  }
}
</script>
<style>
.carousel {
  height: 200px !important;
}
</style>
